import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styles from './css/index.module.sass';
import lost from '../images/real/lost3.jpg';
import lost2 from '../images/reenact/lost.jpg';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <div className={styles.errorPage}>
        <h1>Vous êtes hors DZ</h1>
        <h3>Page inexistante</h3>
        <img src={lost2} alt="lost" />
        <a href="/">Retour à la page d'accueil</a>
    </div>
  </Layout>
);

export default NotFoundPage;
